import React from "react";
import styles from "./LabelLanding.module.scss";
import { bounceIn, Button2, SnapSection } from "../../components";
import { ReactComponent as Checkmark } from "../../assets/SVGs/check-success.svg";
import { ReactComponent as DoubleArrows } from "../../assets/SVGs/double-chevron-right.svg";
import {
  FUNDING_NETWORK_LABEL
} from "../../routes/constants";
import { Link } from "react-router-dom";
import { motion } from "motion/react"
import bgVideos from "../../assets/images/LabelBg.mp4";

function LabelLanding () {

    return (
      <SnapSection className={styles.landing}>
        <div className={styles.videoHeader}>
          <video autoPlay loop playsInline muted alt="Video of various musicians playing and performing" className={styles.video}>
            <source src={bgVideos} type="video/mp4" />
          </video>
          <motion.div className={styles.landing_container} {...bounceIn}>
            <h1 className={styles.title}>
              <span className={styles.title1}>Grow your Roster. </span>
              <span className={styles.title2}>Take back Control.</span>
            </h1>
            <div className={styles.categories}>
              <div className={styles.category}>
                <Checkmark />
                <p>Sign New Clients</p>
              </div>
              <div className={styles.category}>
                <Checkmark />
                <p>Finance New Releases</p>
              </div>
              <div className={styles.category}>
                <Checkmark />
                <p>Fund Operations</p>
              </div>
            </div>
            <Link className={styles.buttonLink}to={FUNDING_NETWORK_LABEL}>
              <Button2 className={styles.mainButton} light><p>See your options</p> <DoubleArrows /></Button2>
            </Link>
          </motion.div>
        </div>
          
      </SnapSection>
    );
}

export default LabelLanding;
