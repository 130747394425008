import React, { Component } from "react";
import styles from "./A2IMLanding.module.scss";
import { Button2 } from "../../components";
import { ReactComponent as DoubleArrows } from "../../assets/SVGs/double-chevron-right.svg";
import { ReactComponent as BeatBread } from "../../assets/SVGs/logos/beatBread-logo.svg";

import { FUNDING_NETWORK_LABEL } from "../../routes/constants";
import { Link } from "react-router-dom";

class A2IMLanding extends Component {
  render() {
    return (
      <>
        <div id="landing" className={styles.landing}>
          <div className={styles.landing_container}>
            <div className={styles.landing_logos}>
              <img
                src={require("../../assets/partnerLogos/a2im-stamp-white.png")}
                alt="a2im logo"
              />
              <p>X</p>
              <BeatBread />
            </div>
            <h1 className={styles.title}>
              <span className={styles.title1}>Grow your Roster. </span>
              <span className={styles.title2}>Take back Control.</span>
            </h1>
            <p className={styles.subtitle}>beatBread’s partnership with A2IM brings its flexible, transparent, data-driven funding options directly to A2IM members.</p>
            <Link
              className={styles.buttonLink}
              to={`${FUNDING_NETWORK_LABEL}?label_id=a2im`}
            >
              <Button2 className={styles.mainButton} light>
                <p>Take Back Control</p> <DoubleArrows />
              </Button2>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default A2IMLanding;
