import React, { Component } from "react";
import styles from "./ACKWidgets.module.scss";
import { ReactComponent as LOGIN_ICON} from "../../assets/SVGs/ownership-key-hand.svg";
import { ReactComponent as MAIL_CHECK} from "../../assets/SVGs/emailIcon.svg";
import Button2 from "../Button2";
import { API_URL, AUTH, FORGOT_PASS_API } from "../../routes/constants";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormField from "../FormField/FormField";
import loginStyles from "../FFLogins/FFLogins.module.scss";
import { EMAIL_REGEX } from "../OnboardingWidget/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import request from "../../utils/request";
import { toast } from "react-toastify";
import { get } from "lodash";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      isPasswordReset: true,
    };
  }
  validationSchemaEmail = Yup.object().shape({
    email: Yup.string()
      .required()
      .matches(EMAIL_REGEX, "Please enter valid email only")
      .label("Email address"),
  });
  handleSubmit = (values) => {
    this.setState((prev) => ({
      ...prev,
      loading: true,
      email: this.state.isPasswordReset ? values.email : prev.email,
    }));
    const payload = {
      emailOrPhone: this.state.isPasswordReset
        ? values.email
        : this.state.email,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${AUTH}${FORGOT_PASS_API}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({ isPasswordReset: false });
        } else {
          toast.error(get(res, "message"));
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(get(err, "message"));
      });
  };
  renderResetPassword = () => {
    return this.state.isPasswordReset ? (
      <>
        <div className={`${loginStyles.headerDiv} ${styles.header}`}>
          <div className={loginStyles.icon}>
            <LOGIN_ICON />
          </div>
          <div className={loginStyles.subHeaderDiv}>
            <h1 className={loginStyles.title}>Forgot Password?</h1>
            <p className={loginStyles.subtitle}>Enter your email below and we will send you a recovery link.</p>
          </div>
        </div>
        <div className={`${loginStyles.loginContent} ${styles.forgotPassword}`}>
          <Formik
            validationSchema={this.validationSchemaEmail}
            initialValues={{ email: "" }}
            onSubmit={this.handleSubmit}
            className={styles.formContainer}
          >
              <Form>
                <div className={`form-group ${loginStyles.fieldContainer}`}>
                  <FormField
                    name="email"
                    placeholder="Email address"
                    type="text"
                    label="Email Address"
                    className={loginStyles.inputField}
                    errorStyle={loginStyles.errorClass}
                  />
                </div>
                <div className={styles.btnDiv}>
                  <Button2 type="submit" className={styles.resetButton} lg>
                    SEND PASSWORD RESET
                  </Button2>
                  <div
                    className={styles.loginLink}
                    onClick={this.props.onClick}
                  >
                    Return to Login
                  </div>
                </div>
              </Form>
          </Formik>
        </div>
      </>
    ) : (
      <>
        <div className={styles.passwordResetDiv}>
          <div className={`${loginStyles.headerDiv} ${styles.header}`}>
            <div className={loginStyles.icon}>
              <MAIL_CHECK />
            </div>
            <div className={loginStyles.subHeaderDiv}>
              <h1 className={loginStyles.title}>Email Sent!</h1>
              <p className={loginStyles.subtitle}>Instructions for creating your new password have been sent to you!</p>
            </div>
          </div>
          <div className={styles.passwordLoginDiv}>
            Didn’t receive a text or email?{" "}
            <span onClick={() => this.handleSubmit(this.state.email)}>
              Resend
            </span>
          </div>
        </div>
      </>
    );
  };
  render() {
    return (
      <div className={`${loginStyles.widgetBox} ${styles.forgotPasswordBox}`}>
        <div className={`${loginStyles.content} ${styles.forgotPasswordDiv}`}>
          {this.state.loading ? (
            <div className={loginStyles.spinDiv}>
              <FontAwesomeIcon icon={faCircleNotch} className="spin" />
            </div>
          ) : (
            <>{this.renderResetPassword()}</>
          )}
        </div>
      </div>
    );
  }
}

export default ForgotPassword;