import React, { useEffect, useRef } from "react";
import "./Shell.scss";
import { Outlet, useLocation } from "react-router-dom";
import { HeaderV3 } from "../../components";
import {
  AGENT_HOME,
  ARTIST_ENROLLMENT,
  ARTIST_SEARCH_ESTIMATES,
  DEAL_COMPARISON,
  FUNDING_NETWORK,
  FUNDING_NETWORK_ARTIST,
  HOME,
  LABELHOME,
} from "../../routes/constants";
import { get } from "lodash";
import { FooterV3, FooterV3Snap } from "../../sections";

const NO_NAVBAR = [AGENT_HOME];
const NO_FOOTER = [];
const SNAP_LAYOUT = [HOME, DEAL_COMPARISON, ARTIST_ENROLLMENT, FUNDING_NETWORK, FUNDING_NETWORK_ARTIST, ARTIST_SEARCH_ESTIMATES, LABELHOME];

function Shell(props) {

  const location = useLocation();
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [location]);

  const renderNavbar = () => {
    if (props.noNavbar || NO_NAVBAR.includes(props.currentLocation)) {
      return <></>;
    } else {
      return (
        <HeaderV3
          home={get(props, "home")}
          currentLocation={get(props, "currentLocation")}
        />
      );
    }
  };

  const renderFooter = () => {
    if (NO_FOOTER.includes(props.currentLocation)) {
      return null;
    } else if (SNAP_LAYOUT.includes(props.currentLocation)) {
      return <FooterV3Snap />;
    } else {
      return <FooterV3 />;
    }
  };

  return (
    <div id="snap" ref={containerRef} className={`shell ${SNAP_LAYOUT.includes(props.currentLocation) ? "snap" : ""}`}>
      {renderNavbar()}
      <Outlet />
      {renderFooter()}
    </div>
  );
}

export default Shell;
