import {
  ABOUT_US,
  ARTIST_SEARCH_ESTIMATES,
  FAQ,
  KNOWLEDGECENTER,
  LABELHOME,
  PARTNERS,
  PRESS,
  GLOSSARY,
} from "../../routes/constants";

export const ABOUTUS = "About Us";
export const NEWS = "News";
export const RESOURCES = "Resources";
export const ARTISTS = "Artist Funding";
export const NETWORK = "Funding Network";
export const DISTRIBUTORS = "Partners";
export const INDIE_LABELS = "Label Funding";
export const OUR_TEAM = "Our Team";
export const FAQs = "FAQs";

export const DRAWER_NAVIGATION = [
  {
    title: "Funding",
    options: [
      { title: "Artists", to: ARTIST_SEARCH_ESTIMATES },
      { title: "Labels and Distributors", to: LABELHOME },
    ],
  },
  {
    title: "About Us",
    options: [
      { title: "Our Team", to: ABOUT_US },
      { title: "Partners", to: PARTNERS },
      { title: "News", to: PRESS },
    ],
  },
  {
    title: "Resources",
    options: [
      { title: "Knowledge Center", to: KNOWLEDGECENTER },
      { title: "FAQs", to: FAQ },
      { title: "Glossary", to: GLOSSARY},
      //{ title: "Deal Structure Glossary", to: GLOSSARY}
    ],
  },
];
