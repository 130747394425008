import SnapSection from "../SnapSection";
import styles from "./VerticalSwiper.module.scss";
import { motion, } from "motion/react"
import { ReactComponent as RightCorner } from "../../assets/SVGs/right-corner.svg";
import { ReactComponent as LeftCorner } from "../../assets/SVGs/left-corner.svg";

function VerticalSwiperV2(props) {
  return (
    <div className={`${props.className} ${styles.container} ${props.light ? styles.light : styles.dark}`}>  
        {Object.entries(props.children).map((e, i) => {
            return (
                <SnapSection key={i} id={`section ${i}`}className={styles.section} >
                    <motion.div className={styles.slide} initial={{ opacity: 0, scale: 0.75, transition: { duration: 0.8, ease: [0, 0.71, 0.2, 1.01],}, }} whileInView={{ opacity: 1, scale: 1, transition: { duration: 0.8, ease: [0, 0.71, 0.2, 1.01],}}}>
                        {props.children[i]}
                        <ul className={styles.indicator}>
                            {Object.entries(props.children).map((e, a) => {
                                return (
                                    <li key={a}><a href={`#section ${a}`}><div className={`${styles.bullet} ${i === a ? styles.active : styles.inactive}`}></div></a></li>
                                );
                            })}
                        </ul>
                    </motion.div>
                </SnapSection>
            );
        })}
        {props.light ? (<LeftCorner className={styles.left} />) : ("") }
        {props.light ? (<RightCorner className={styles.right} />) : ("") }
    </div>
  );
}

export default VerticalSwiperV2;