import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DRAWER_NAVIGATION } from "./constants";
import styles from "./HeaderV3.module.scss";
import { ReactComponent as BeatBreadLogo } from "../../assets/SVGs/logos/beatBreadLogoResponsive.svg";
import { ARTIST_ENROLLMENT, DEAL_COMPARISON, FUNDING_NETWORK } from "../../routes/constants";
import { Box, Drawer } from "@mui/material";
import { ReactComponent as Hamburger } from "../../assets/SVGs/hamburger-menu.svg";
import { ReactComponent as Xclose } from "../../assets/SVGs/x-close.svg";
import NavDrawer from "./helper";
import Button2 from "../Button2";

class HeaderV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  toggleMenu = (override) => {
    this.setState({ showMenu: override });
  };

  renderSecondaryNavbar = () => {
    return (
      <>
        {DRAWER_NAVIGATION.map((item, i) => (
          <NavDrawer items={item.options} title={item.title} key={i} onUpdateState={this.toggleMenu}/>
        ))}
      </>
    );
  };

  list = () => (
    <div className={styles.mobileNavPopup}>
      <Box role="presentation" >
        <div>
          {this.renderSecondaryNavbar()}
          <div className={styles.mobileLoginContainer}>
            <Button2 light sm onClick={() => this.toggleMenu(false)} to={FUNDING_NETWORK} className={styles.loginBtn} ><p>Get&nbsp;Offers</p></Button2>
            <Button2 light sm outline to={DEAL_COMPARISON} className={styles.loginBtn} onClick={() => this.toggleMenu(false)}><p>Compare Offers</p></Button2>
            <Button2 light sm tertiary to={`${ARTIST_ENROLLMENT}?isLogin=true`} className={styles.loginBtn} onClick={() => this.toggleMenu(false)}><p>Log&nbsp;In</p></Button2>
          </div>
        </div>
      </Box>
      <div className={styles.mobileEmpty} onClick={() => this.toggleMenu(false)}></div>
    </div>
  );

  render() {
    return (
      <>
        <div className={styles.mainNavContainer}>
          <div className={styles.primaryNavbarContainer}>
            <div className={styles.navbarLeft}>
              <div className={styles.navbarLogo}>
                <Link to={this.props.home} className={styles.navLogo}>
                  <BeatBreadLogo />
                </Link>
              </div>
              <div className={styles.dropdowns}>
                {this.renderSecondaryNavbar()}
              </div>
            </div>
            <div className={styles.navbarRight}>
            <Button2 light xs tertiary to={`${ARTIST_ENROLLMENT}?isLogin=true`} className={styles.loginBtn}><p>Log&nbsp;In</p></Button2>
              <div className={styles.navLinksContainer}>
                <Button2 light xs outline to={DEAL_COMPARISON} className={styles.loginBtn}><p>Compare&nbsp;Offers</p></Button2>
                <Button2 light xs to={FUNDING_NETWORK} className={styles.loginBtn} ><p>Get&nbsp;Offers</p></Button2>
              </div>
            </div>
          </div>
          <div className={styles.navMobileMenuHeader}>
            <Link to={this.props.home} className={styles.navMobileLogo} onClick={() => this.toggleMenu(false)}>
              <BeatBreadLogo />
            </Link>
            <div
              className={styles.navMobileMenuButton}
              onClick={() => this.toggleMenu(!this.state.showMenu)}
            >
              {this.state.showMenu ? <Xclose /> : <Hamburger />}
            </div>
          </div>
          <Drawer
            variant="persistent"
            anchor={"top"}
            open={this.state.showMenu}
            onClose={() => {
              this.toggleMenu(false);
            }}
            className={`${styles.mobileDrawer} ${
              this.state.showMenu ? styles.open : ""
            }`}
          >
            {this.list()}
          </Drawer>
        </div>
      </>
    );
  }
}

export default HeaderV3;
