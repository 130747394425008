import React, { Component } from "react";
import styles from "../DealCompForm.module.scss";
import Button2 from "../../Button2";
import { ReactComponent as AlertIcon } from "../../../assets/SVGs/Alert.svg";
import { ReactComponent as DoubleArrow } from "../../../assets/SVGs/double-chevron-right.svg";
import segment from "../../../utils/segment";
import variables from "../../../mixins.scss";

class DealCompFormErr extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    segment.track.artistFailed()
  }

  render() {
    return (
      <div className={styles.widgetBox}>
        <div className={styles.content}>
          <div className={styles.icon}>
            <svg id={styles.svgGradient} aria-hidden="true" focusable="false"><linearGradient id="purpleGradient" x2="1" y2="1"><stop offset="-11.24%" stop-color={variables.blueVioletColor}></stop><stop offset="59.06%" stop-color="#7965BF"></stop></linearGradient></svg>
            <AlertIcon />
          </div>
          <h2 className={styles.title}>Error!</h2>
          <p className={styles.subtitle}>
          <b>Your form failed to submit!</b><br/>Try again in a few minutes.
          </p>
        </div>
        <Button2 full onClick={this.props.onClick}>
          <p>Try Again</p><DoubleArrow />
        </Button2>
      </div>
    );
  }
}

export default DealCompFormErr;
