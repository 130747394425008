import React, { Component } from "react";
import styles from "../DealCompForm.module.scss";
import Button2 from "../../Button2";
import { ReactComponent as ThanksIcon } from "../../../assets/SVGs/checkmark-spiked.svg";
import { ReactComponent as DoubleArrows } from "../../../assets/SVGs/double-chevron-right.svg";
import { Link } from "react-router-dom";
import variables from "../../../mixins.scss";

class DealCompFormErr extends Component {
  constructor() {
    super();
    this.state = {
      clicked: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({
      clicked: true,
    });
  }

  render() {
    return (
      <div className={styles.widgetBox}>
        <div className={styles.content}>
          <div className={styles.icon}>
            <svg id={styles.svgGradient} aria-hidden="true" focusable="false"><linearGradient id="purpleGradient" x2="1" y2="1"><stop offset="-11.24%" stop-color={variables.blueVioletColor}></stop><stop offset="59.06%" stop-color="#7965BF"></stop></linearGradient></svg>
            <ThanksIcon />
          </div>
          <h2 className={styles.title}>Thank You!</h2>
          <p className={styles.subtitle}>
            We are reviewing the information that you provided. We'll be in
            touch in the next few days.
          </p>
          <Link to="/" className={styles.homeLink}>
            <Button2 className={styles.button2} full>
                <p>RETURN TO HOME</p>
              <DoubleArrows />
            </Button2>
          </Link>
        </div>
      </div>
    );
  }
}

export default DealCompFormErr;
