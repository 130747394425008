import React from "react";
import style from "./Button2.module.scss";
import { Link } from "react-router-dom";

function Button2(props) {

  const disabled = props.disabled || false;

  return (
    <>
      {props.href ? (
        <a
          className={`
            ${style.button2} 
            ${props.className} 
            ${props.linkButton ? style.linkButton : props.tertiary ? style.tertiary : props.outline ? style.outline : ""}
            ${props.light ? style.light : props.white ? style.white : style.dark}
            ${props.full ? style.full : ""}
            ${props.xs ? style.xs : props.sm ? style.sm : props.md ? style.md : props.lg ? style.lg : style.xl}
          `}
          href={props.href || "#"}
          target={props.target || ""} 
          rel={props.rel || ""}
          alt=""
          disabled={disabled}
        >
          {props.children || props.value || <p>Submit</p>}
        </a>
      ) : props.to ? (
        <Link
          type={props.type || "button2"}
          className={`
            ${style.button2} 
            ${props.className} 
            ${props.linkButton ? style.linkButton : props.tertiary ? style.tertiary : props.outline ? style.outline : ""}
            ${props.light ? style.light : props.white ? style.white : style.dark}
            ${props.full ? style.full : ""}
            ${props.xs ? style.xs : props.sm ? style.sm : props.md ? style.md : props.lg ? style.lg : style.xl}
          `}
          to={props.to}
          disabled={disabled}
          onClick={() => {
            if (typeof props.onClick === "function") {
              props.onClick();
            }
          }}
        >
          {props.children || props.value || <p>Submit</p>}
        </Link>
      ) : (
        <button
          type={props.type || "button2"}
          className={`
            ${style.button2} 
            ${props.className} 
            ${props.linkButton ? style.linkButton : props.tertiary ? style.tertiary : props.outline ? style.outline : ""}
            ${props.light ? style.light : props.white ? style.white : style.dark}
            ${props.full ? style.full : ""}
            ${props.xs ? style.xs : props.sm ? style.sm : props.md ? style.md : props.lg ? style.lg : style.xl}
          `}
          onClick={() => {
            if (typeof props.onClick === "function") {
              props.onClick();
            }
          }}
          disabled={disabled}
        >
          {props.children || props.value || <p>Submit</p>}
        </button>
      )}
    </>
  );
}

export default Button2;
