import React from "react";
import styles from "./LandingV3.module.scss";
import bgVideos from "../../assets/images/homepageBG.mp4";
import { bounceIn, Button2 } from "../../components";
import { DEAL_COMPARISON, FUNDING_NETWORK } from "../../routes/constants";
import { motion } from "motion/react"

function LandingV3 () {

  return (
    <div className={styles.videoHeader}>
      <video autoPlay loop playsInline muted alt="Video of various musicians playing and performing" className={styles.video}>
        <source src={bgVideos} type="video/mp4" />
      </video>
      <motion.div id="landing" className={styles.landing} {...bounceIn}>
        <h1 className={styles.titleCtn}>
          <span className={styles.primary}>More Choices.</span>
          <span className={styles.secondary}>Better Funding.</span>
        </h1>
        <div className={styles.landingActions}>
          <Button2 light to={DEAL_COMPARISON} outline className={styles.actionButton}><p>Compare Outside Offers</p> </Button2>
          <Button2 light to={FUNDING_NETWORK} className={styles.actionButton}><p>Get New beatBread Offers</p></Button2>
        </div>
      </motion.div>
    </div>
  );
}

export default LandingV3;
