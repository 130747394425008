import React, { Component } from "react";
import styles from "./DealCompForm.module.scss";
import DealCompFormErr from "./DealCompFormErr";
import DealCompFormSuc from "./DealCompFormSuc";
import { Input3, Button2, PopUp, SearchBar, TextArea } from "..";
import {
  EMAIL_REGEX,
  STAGES,
  typeOptions,
  switchOptions,
  PHONE_REGEX,
} from "./constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { ReactComponent as Arrows } from "../../assets/SVGs/double-chevron-right.svg";
import { HUBSPOT_API_URL, TERM_OF_USE } from "../../routes/constants";
import {
  checkedIcon,
  uncheckedIcon,
} from "../../pages/SinglePageSignup/constants";
import { Checkbox } from "@mui/material";
import { BEATBREAD_PRIVACY_POLICY } from "../OnboardingWidget/constant";
import SingleFileUploadFiles from "../FileUpload/SingleFileUploadFiles";
import _ from "lodash";
import request from "../../utils/request";

class DealCompForm extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      label: "",
      phone: "",
      switch: "",
      type: "",
      id: "",
      artistName: "",
      image: "",
      description: "",
      typeSettings: {
        "Artist": false,
        "Label": false,
      },
      switchSettings: {
        "Yes": false,
        "No": false,
        "Unsure": false,
      },
      selectOptions: [],
      files: [],
      showAlert: false,
      alertText: "",
      stage: STAGES.INITIAL,
      loader: false,
      checked: false,
    };
  }

  handleReturn = (key, value) => {
    this.setState({ [key]: value });
  };

  resetState = (additionalStates = {}) => {
    this.setState({
      name: "",
      email: "",
      label: "",
      phone: "",
      switch: "",
      type: "",
      id: "",
      artistName: "",
      image: "",
      description: "",
      typeSettings: typeOptions,
      switchSettings: switchOptions,
      ...additionalStates,
    });
  };

  triggerAlert = (alertText, delay = 5000) => {
    this.setState(
      {
        showAlert: true,
        ...(alertText ? { alertText: alertText } : {}),
      },
      () => {
        setTimeout(
          () =>
            this.setState({ showAlert: false }, () => {
              if (alertText) {
                setTimeout(() => {
                  this.setState({ alertText: "" });
                }, delay);
              }
            }),
          delay
        );
      }
    );
  };

  changeComponentErr = () => {
    return <DealCompFormErr onClick={this.renderInitial} />;
  };

  changeComponentSuc = () => {
    return <DealCompFormSuc />;
  };

  handleChange = (input, e, type, label) => {
    if (type === "checklist") {
      this.setState((prevState) => ({
        ...prevState,
        [input]: label,
      }));
      this.setState((prevState) => ({
        ...prevState,
        typeSettings: {
          ...typeOptions,
          [label]: !e,
        },
      }));
    } else if (type === "radio") {
      this.setState((prevState) => ({
        ...prevState,
        [input]: label,
      }));
      this.setState((prevState) => ({
        ...prevState,
        switchSettings: {
          ...switchOptions,
          [label]: !e,
        },
      }));
    } else if (type === "dropdown") {
      this.setState({ [input]: e });
    } else {
      this.setState({ [input]: e.target.value });
    }
  };
  emailValidation = () => EMAIL_REGEX;

  handleSubmit = () => {
    this.setState({ loader: true });
    const params = new URLSearchParams(window.location.search);
    const { files } = this.state;
    const hubspotLabel = params.get("label_id");
    const formData = new FormData();
    formData.append("type", 
      Object.entries(this.state.typeSettings)
      .filter((a) => a[1] === true)
      .map((e) => e[0]));
    formData.append("label", this.state.label ? this.state.label : "None");
    formData.append("artistName", this.state.artistName ? this.state.artistName : "None");
    formData.append("id", this.state.id ? this.state.id : "None");
    formData.append("switch", 
      Object.entries(this.state.switchSettings)
      .filter((a) => a[1] === true)
      .map((e) => e[0]));
      formData.append("name", this.state.name);
    formData.append("email", this.state.email);
    formData.append("phone", this.state.phone);
    formData.append("description", this.state.description);
    files.forEach((file) => {
      formData.append("reports", file, file.name);
    });
    formData.append("checked", this.state.checked);
    formData.append("label_form_source", hubspotLabel ? hubspotLabel : "beatBread");
    const data = {
      method: "POST",
      body: formData,
      headers: {
      "Access-Control-Allow-Origin": "*",
      "Accept": "application/json"
      },
    };
    const requestURL = `${HUBSPOT_API_URL}/hubspot/deal_comparison_contact/`;;
    request(requestURL, data)
          .then((res) => {
            this.setState({ loader: false });
            this.setState({ stage: "success" });
          })
          .catch((err) => {
            this.setState({ loader: false });
            this.setState({ stage: "error" });
          })
          .finally(() => {
            this.resetState();
          });
  };

  handleCheckboxChange = () => {
    this.setState({ checked: !this.state.checked });
  };

  handleLabel = () => {
    const label = this.state.type === "Artist" ? "artist": this.state.type === "Label" ? "label" : "artist or label";
    const newLabel =  "Can this "+label+" switch distributors?"
    return newLabel;
  };

  updateArtist = (e) => {
    if (e) {
      document.getElementsByClassName(
        "select-search__input-container"
      )[0].style.display = "none";
      this.setState({ id: e.value, artistName: e.label, image: e.imageUrl });
    }
  };
  updateFileList = (file, isDelete) => {
    const { files } = this.state;
    if (isDelete) {
      _.remove(files, (f) => f.name === file.name);
    } else {
      files.push(file);
    }
    this.setState({ files });
  };

  renderSearchBar = () => (
    <SearchBar standalone onUpdateArtist={(e) => this.updateArtist(e)}/>
  );

  renderWidgetForm = () => (
    <div className={styles.widgetBoxForm}>
      <PopUp logo open={this.state.showAlert}>
        {this.state.alertText}
      </PopUp>
      <div className={styles.heading}>
        <h2 className={styles.ogTitle}>
        Share a few details and we’ll be in touch.
        </h2>
      </div>
      <div className={styles.form}>
      <Input3
          input="boxes"
          title="Are you looking to compare deals for an artist or a label?"
          keyName="type"
          boxes={this.state.typeSettings}
          value={this.state.type}
          onChange={this.handleChange}
          return={this.handleReturn}
          disabled={this.state.loader}
          full
          md
          required
        />
        {this.state.type === "Artist" && (
          this.renderSearchBar()
        )}
        {this.state.type === "Label" && (
          <Input3
            label="Label Name"
            keyName="label"
            autocomplete="organization"
            placeholder="beatBread"
            value={this.state.label}
            onChange={this.handleChange}
            return={this.handleReturn}
            disabled={this.state.loader}
            required
          />
        )}
        <Input3
          label={this.handleLabel()}
          keyName="switch"
          input="radio"
          boxes={this.state.switchSettings}
          value={this.state.switch}
          onChange={this.handleChange}
          return={this.handleReturn}
          disabled={this.state.loader}
          required
        />
        <Input3
          label="Contact Name"
          className={styles.formInput}
          keyName="name"
          autocomplete="name"
          placeholder="John Doe"
          onChange={this.handleChange}
          value={this.state.name}
          return={this.handleReturn}
          disabled={this.state.loader}
          required
        />
        <Input3
          label="Email"
          keyName="email"
          autocomplete="email"
          placeholder="johndoe@email.com"
          value={this.state.email}
          onChange={this.handleChange}
          return={this.handleReturn}
          disabled={this.state.loader}
          required
        />
        <Input3
          label="Phone Number"
          keyName="phone"
          autocomplete="phone"
          placeholder="+1 (555) 555-5555"
          value={this.state.phone}
          onChange={this.handleChange}
          return={this.handleReturn}
          disabled={this.state.loader}
          required
        />
        <TextArea 
          title="Tell us all about your offer(s)"
          placeholder="Begin Typing..."
          keyName="description"
          value={this.state.description}
          onChange={this.handleChange}
          return={this.handleReturn}
          disabled={this.state.loader}
          required
        />
        <SingleFileUploadFiles
          updateFileList={this.updateFileList}
          isUploadReports={true}
          link={true}
          linkDescription="Have a copy of your offer(s)?"
          selectOptions={this.state.selectOptions}
          totalFiles={this.state.files.length}
          loading={this.state.loader}
          {...this.props}
        />
      </div>
      <div className={styles.checkboxClass}>
        <Checkbox
          sx={{
            color: "#594aa5",
            padding: "2px 0 0 0",
            "&.Mui-checked": {
              color: "#594aa5",
            },
          }}
          icon={uncheckedIcon}
          checkedIcon={checkedIcon}
          onChange={this.handleCheckboxChange}
        />
        <div className={styles.checkLabel}>
          I agree to beatBread’s{" "}
          <a href={TERM_OF_USE} target="_blank" rel="noreferrer">
            Terms of Service
          </a>{" "}
          and acknowledge beatBread’s{" "}
          <a href={BEATBREAD_PRIVACY_POLICY} target="_blank" rel="noreferrer">
            Privacy Notice
          </a>
          . I consent to receive beatBread messages via SMS. Standard call,
          message, or data rates may apply.
        </div>
      </div>
      <Button2
        full
        onClick={this.handleValid}
        className={styles.submit}
        type="submit"
        childStyle={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
        disabled={this.state.loader}
      >
        {this.state.loader && (
          <FontAwesomeIcon icon={faCircleNotch} className={styles.spin} />
        )}
        <p>SUBMIT</p> <Arrows />
      </Button2>
    </div>
  );

  renderInitial = () =>
    this.setState({
      stage: STAGES.INITIAL,
    });

  validationSchema = 
    Yup.object().shape({
      type: Yup.array()
        .label("Deal Type")
        .required()
        .min(1, "Please Select which deal type you are looking for."),
      
      label: Yup.string().label("Label Name"),
      id: Yup.string().label("Artist Name"),
      switch: Yup.array()
        .label("Switch Distributors")
        .required()
        .min(1, "Please let us know if you are able to switch distributors."),
      name: Yup.string().label("Contact Name").required(),
      email: Yup.string()
        .label("Email")
        .required()
        .matches(EMAIL_REGEX, "Please enter valid email"),
      phone: Yup.string().label("Phone Number").required().matches(PHONE_REGEX, "Please enter a valid phone number"),
      description: Yup.string().label("Description").required("Please tell us about your offer(s)"),
      checked: Yup.bool().required().oneOf(
        [true],
        "Please agree to the Privacy Policy before proceeding."
      )
    })
    .test("back", function _(value) {
      const a = !!(value.label || value.id);
      if (!a) {
        return new Yup.ValidationError(
          "Please input an artist or label name.", //Message
          "null",
          "name", //error name
          "required" //type
        );
      }
      return true;
    })
  
  ;

  handleValid = async () => {
    const values = {
      type: Object.entries(this.state.typeSettings)
        .filter((a) => a[1] === true)
        .map((e) => e[0]),
      label: this.state.label,
      id: this.state.id,
      switch: Object.entries(this.state.switchSettings)
        .filter((a) => a[1] === true)
        .map((e) => e[0]),
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      description: this.state.description,
      checked: this.state.checked,
    };
    const validate = await this.validationSchema.isValid(values);
    if (!validate) {
      this.validationSchema
        .validate(values, { abortEarly: false })
        .catch((err) => {
          const errorMsg = err.inner.length > 0 && err.inner[0].message;
          this.triggerAlert(errorMsg);
        });
    } else {
      this.handleSubmit();
    }
  };
  render() {
    switch (this.state.stage) {
      case STAGES.INITIAL:
        return this.renderWidgetForm();
      case STAGES.SUCCESS:
        return this.changeComponentSuc();

      case STAGES.ERROR:
        return this.changeComponentErr();

      default:
        return this.renderWidgetForm();
    }
  }
}

export default DealCompForm;
