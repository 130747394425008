import { ReactComponent as DistributorMad } from "../../assets/partnerLogos/primary-madsolutions.svg";
import { ReactComponent as DistributorSmith } from "../../assets/partnerLogos/smith-logo-white.svg";
import { ReactComponent as DistributorDelta } from "../../assets/partnerLogos/DELTA_Logo.svg";
import { ReactComponent as DistributorTooLost } from "../../assets/partnerLogos/primary-toolost.svg";
import { ReactComponent as DistributorUnderground } from "../../assets/partnerLogos/KingUndergroundLogo.svg";
import DistributorVictor from "../../assets/partnerLogos/victorvictorwordmark.png";
import StevenVictor from "../../assets/partnerLogos/steven-victor.webp";
import DanEnglander from "../../assets/partnerLogos/kingUnderground.webp";

export const testimonialList = [
    {
        partner: "Victor Victor Worldwide",
        logo: <img src={DistributorVictor} alt="Victor Victor Worldwide Logo"/>,
        quote: "“Control, independence, and flexibility are central to Victor Victor’s success.  I had several large offers to fund my business, and beatBread’s comparison tools helped me analyze the pros and cons of each and then find an even better deal.”",
        country: "USA",
        author: "Steven Victor",
    },
    {
        partner: "M.A.D. Solutions",
        logo: <DistributorMad/>,
        quote: "“beatBread label funding has enabled us to expand our operations and acquire new repertoire.  In addition to giving us funding on a deal-by deal basis, we have a fund that we can draw additional funds from as we see fit.   beatBread gave us the financial muscle to compete more effectively in the fast growing African market.”",
        country: "Nigeria",
        author: "Bugwu Aneto-Okeke",
    },
    {
        partner: "Smith Group",
        logo: <DistributorSmith/>,
        quote: "“Teaming up with beatBread was straightforward and rewarding. They offered a range of options and favorable terms that made our collaboration both simple and satisfying.”",
        country: "USA",
    },
    {
        partner: "Delta Music",
        logo: <DistributorDelta/>,
        quote: "“Act like an indie, financially supported like a major. That’s the future”",
        country: "Germany",
    },
    {
        partner: "Too Lost",
        logo: <DistributorTooLost/>,
        quote: "“beatBread has been central to our artist acquisition and helped us become the fastest growing indie distributor in the world. Their flexible funding has been a core pillar that has enabled us to add over 50,000 artists to our platform, and double our revenues in each of the last two years.”",
        country: "USA",
        author: "Gregory Hirschhorn",
    },
    {
        partner: "KingUnderground",
        logo: <DistributorUnderground/>,
        quote: "“I had what I thought were two quite attractive distribution offers. After seeing the output of beatBread’s Deal Comparison Tool, I realized that there were several scenarios where I could have ended up strapped for cash. Although I didn’t take beatBread’s offer in the end, I took funding from one of their Network Partners.  Very glad I found beatBread when I did!”",
        country: "England",
        author: "Dan Englander",
    },
]

export const imgList = [
    {
        partner: "Victor Victor Worldwide",
        logo: <img src={DistributorVictor} alt="Victor Victor Worldwide Logo"/>,
        image: StevenVictor,
        quote: "“Control, independence, and flexibility are central to Victor Victor’s success.  I had several large offers to fund my business, and beatBread’s comparison tools helped me analyze the pros and cons of each and then find an even better deal.”",
        country: "USA",
        author: "Steven Victor",
    },
    {
        partner: "KingUnderground",
        logo: <DistributorUnderground/>,
        image: DanEnglander,
        quote: "“I had what I thought were two quite attractive distribution offers. After seeing the output of beatBread’s Deal Comparison Tool, I realized that there were several scenarios where I could have ended up strapped for cash. Although I didn’t take beatBread’s offer in the end, I took funding from one of their Network Partners.  Very glad I found beatBread when I did!”",
        country: "England",
        author: "Dan Englander",
    },
]